:root {
  --color-border-muted: hsla(210, 18%, 87%, 1);
  --bodybackground-color: #f3f2ef;
  --primary: #052b77;
  --secondary: #70c8ee;
  --background-material: #f3f2ef;
}

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

@media print {
  html {
    display: none !important;
  }

  body {
    display: none !important;
  }

  body::after {
    content: "Print Restricted";
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.highcharts-credits,
.highcharts-title {
  display: none;
}

.tableFixedHeight {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 500px) {
  .dateButton {
    min-width: 0 !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
}

.blink {
  animation: blinker 0.75s linear infinite;
  color: #2f388e;
  font-family: sans-serif;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

blink {
  -webkit-animation-name: blink;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-duration: 1s;
}

.extraRadius {
  border-radius: 15px;
}

.forumpostcard {
  margin-top: 20px;
}

.black_shde_back {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: #000a;
}

.last_released_text_modal {
  font-weight: 700;
}

.alert_dialog {
  position: fixed;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  border-radius: 14px;
  z-index: 50;
}

.newsForumItemBox {
  margin-bottom: 10px !important;
  cursor: pointer;
}

.alert_dialog_title {
  font-size: 21px;
  font-weight: bolder;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.alert_dialog_title_box {
  margin-top: 20px;
}

.alert_dialog span {
  color: #444;
  font-size: 15px;
}

.alert_dialog_body {
  margin-bottom: 20px;
}

.alert_dialog_button {
  height: 45px;
  width: inherit;
  border: none;
  text-align: center;
  background: none;
  color: var(--blue);
}

.alert_dialog_button.btn2 {
  border-top: 0.3px solid #aaa;
  /* border-left: 0.3px solid #aaa; */
}

.alert_dialog_button.btn1 {
  border-top: 0.3px solid #aaa;
  border-right: 0.3px solid #bbb;
}

.page404_box {
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

/* ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}


/* Track */

/* ::-webkit-scrollbar-track {
    background: #f1f1f1;
} */

/* Handle */

/* ::-webkit-scrollbar-thumb {
    background: #888;
} */

/* Handle on hover */

/* 
 ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

body {
  background-color: var(--bodybackground-color) !important;
  min-height: 100vh;
}

.pdfdocbox {
  overflow: hidden;
}

button:focus {
  outline: unset;
  outline: unset;
}

.nav-item {
  margin-left: 15px;
  margin-right: 15px;
  height: 50px;
  width: 90px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.navbar-brand {
  margin-top: 0px;
  display: flex;
  flex-flow: column;
  width: 85px;
  height: 60px;
  align-items: center;
}

.navbar-brand a {
  color: #19173d;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-weight: 700;
}

.navbar-brand img {
  height: 26px !important;
  width: 26px !important;
  border-radius: 50% !important;
  padding: 1px;
}

.navbar-nav {
  justify-content: center;
  width: 420px;
}

.nav-link {
  display: flex;
  flex-flow: column;
  height: 80px;
  text-align: center;
  font-size: 11px;
  align-items: center;
  line-height: 20px;
}

.collapse.navbar-collapse {
  transform: translateX(-29px);
}

.nav-item .nav-link {
  color: #19173d;
}

.nav-link img {
  height: 30px;
  width: 34px;
  transform: scale(0.8);
  transition: 0.2s;
}

.nav-item.active .nav-link {
  color: #72c4f1;
  font-weight: 700;
}

.nav-item.active .nav-link img {
  height: 30px;
  width: 43px;
  transform: scale(1);
}

.section_divider {
  margin: 10px 0;
}

.navbar-collapse.float-left.collapse {
  margin-right: 50px;
}

.authPage_icon_div {
  display: flex;
  justify-content: center;
}

.authPage_icon {
  height: 50px;
}

.upgradebtnonpopup {
  background-color: var(--primary);
  border-radius: 10px;
  border: none;
  color: #fff;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px 0px 6px 1px gray;
}

.headerIconImgs {
  height: 40px;
  max-height: 40px;
}

.headerprofile {
  right: 0 !important;
  position: absolute !important;
  margin-top: 18px !important;
  border: none !important;
  font-size: 15px !important;
  margin-right: -12px !important;
}

.headerprofile_chip_dropod {
  margin-left: 10px;
}

.headerprofile_chip {
  right: 0 !important;
  position: absolute !important;
  border: none !important;
  font-size: 15px !important;
}

.headerprofile_chip:hover,
.headerprofile_chip:active,
.headerprofile_chip:focus {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.headerprofile::after {
  content: "";
  height: 50px;
  width: 115px;
  background: url("https://ashom.app/assets/icons/Dropdown.svg") no-repeat -30px -50px
    fixed;
}

.menuheader,
.menuheader ul {
  display: flex !important;
  flex-direction: column !important;
  width: 200px !important;
  overflow-x: hidden !important;
}

.menuitemHeader {
  justify-content: start !important;
  padding-left: 10px !important;
}

.menuitemHeader .MuiSvgIcon-root {
  margin-right: 10px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex;
  flex-flow: column;
  width: 186px;
  justify-content: space-between;
}

.navbar-back {
  background-color: white;
  box-shadow: 0px 0px 1px 0px #000;
  margin-right: 0px !important;
  padding-right: 15px !important;
}

.auth_logo {
  margin-top: 50px;
}

.authPage_form_div {
  border: 1px solid var(--color-border-muted);
  border-radius: 20px !important;
  padding: 30px;
  background-color: white;
}

.authPage_signintxt_div {
  text-align: center;
  display: flex;
  justify-content: center;
}

.auth_welcome_text {
  text-align: center;
  line-height: 15px;
  font-size: 28px;
}

.ekKbYE {
  margin: 0px 13px 0 0 !important;
}

.hxRXRN {
  position: initial !important;
  height: 36px;
}

.eoSYrG {
  width: 100% !important;
}

.hxRXRN > .wrapper {
  width: 285px !important;
  border: none !important;
}

.auth_to_ashom_text {
  text-align: center;
  font-weight: 400;
  color: #9b9b9b;
  font-size: 13px;
  font-weight: bold;
}

.mainheading_div {
  height: 100px;
  padding: 10px;
}

.authPage_signintxt_div h1 {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.5px;
  text-align: center;
}

.auto_page_row {
  padding-top: 30px;
  padding-bottom: 8px;
}

.loginbtn_div {
  display: flex;
  justify-content: center;
  padding: 0;
}

.loginbtn {
  width: 80%;
  background-color: #000030;
  color: white;
  border-radius: 20px;
  font-weight: 500;
  border: none;
  padding: 10px;
}

.form_label_rember_txt {
  color: #333;
  font-size: 14px;
}

.form_label_txt {
  display: block;
  margin-bottom: 7px;
  font-weight: 400;
  text-align: left;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.6);
}

a.form_label_txt {
  color: blue;
}

.dont_have_account_row {
  padding-bottom: 20px;
}

.dont_have_account_txt_div {
  display: flex;
  justify-content: center;
  text-align: center;
  border: var(--color-border-muted);
  padding: 20px;
  color: #fff;
  height: 60px;
}

.google_round_logo {
  background: white;
  padding: 5px;
  border-radius: 50%;
  height: 45px;
}

.linked_round_logo {
  width: 45px;
  border: none;
  padding: 10px;
  height: 45px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("https://ashom.app/assets/icons/linkedin_round_icon.svg");
}

.mid-section-follow-us-twitter {
  display: flex;
  align-items: center;
}

.mid-section-follow-us-linkedin {
  display: flex;
  align-items: center;
}

.mid-section-follow-us-fb {
  display: flex;
  align-items: center;
  /* margin-left: 8px; */
}

.input_err {
  color: red;
  font-size: 12px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.flags_div {
  overflow-x: auto;
  /* display: flex; */
}

.contrieslist_div {
  overflow-x: auto;
  display: flex;
}

.flags_div div,
.contrieslist_div div {
  margin-top: 0px;
}

.chipborder_only {
  border: 1px solid #a2a0a0 !important;
  border-radius: 16px !important;
}

.chipborder {
  border: 1px solid #a2a0a0 !important;
  border-radius: 16px !important;
  /* width: 100px !important; */
  width: 95px;
  margin-top: 2px !important;
  display: flex !important;
  justify-content: start !important;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  -webkit-appearance: none;
  -webkit-box-shadow: -1px 2px 1px -1px rgb(0 0 0 / 10%),
    0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 6%) !important;
  box-shadow: -1px 2px 1px -1px rgb(0 0 0 / 10%),
    0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 6%) !important;
}

.chipborder.active {
  background-color: rgba(0, 0, 0, 0.08);
}

.countrychips_homepage {
  width: 92px !important;
  display: flex !important;
  justify-content: start !important;
}

.styles_scroll-to-top__2A70v {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  background: #79c7f2 !important;
}

.newsforumchip {
  padding-left: 0px;
  padding-right: 10px;
  margin-top: 4px;
}

.styles_scroll-to-top__2A70v svg {
  height: 20px !important;
  fill: #19327f !important;
}

.flag-dropdown {
  background: transparent;
  border: none;
}

.btn_trans {
  background: transparent;
  border: none;
}

.searchclose {
  position: absolute;
  right: 11px;
  top: 1px;
  font-size: 14px;
}

.labelasheading {
  /* font-family: monospace; */
  font-family: "Segoe UI";
  font-weight: 500;
  color: #474545;
  /* line-height: 22px; */
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 18px;
  /* padding-bottom: 10px; */
}

.labelasheading-1 {
  /* font-family: monospace; */
  font-family: "Segoe UI";
  font-weight: 500;
  color: #474545;
  /* line-height: 22px; */
  align-items: center;
  margin-bottom: 0;
  font-size: 18px;
}

.label-2 {
  padding-left: 6.7em;
}

.labelasheading-inside {
  color: #474545;
  padding: 0 1.5em;
}

.divSteps {
  font-family: monospace;
  font-size: 14px;
  background-color: aliceblue;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: -4px -4px 10px #0000001f; */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.divSteps-in {
  padding: 6px 25px;
  font-size: 13px;
  font-family: "Segoe UI";
}

.divStepsColor {
  background-color: #cef1ff;
}

.divStep {
  font-weight: 800;
  font-family: "Segoe UI";
  font-size: 14px;
  color: #303030;
  /* background-color: #FFFFFF; */
  padding: 3px 20px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100px;
  text-align: center;
}

.radioBtn {
  padding-left: 5em;
}

.news_label_with_search_row {
  background: rgb(238, 237, 239);
  height: 36px;
  margin-bottom: 16px;
  margin-top: 0px;
}

.company_details_period_section,
.company_details_document_section {
  height: 635px;
  padding: 10px;
  margin-bottom: 10px;
}

.company_details_document_section_reports {
  padding: 20px;
  margin-bottom: 10px;
}

.noButton {
  background-color: transparent;
  border: none;
}

.tox_company_name {
  font-size: 15px;
  line-height: 17px;
  display: inline-block;
  font-family: "Segoe UI";
  color: #19173d;
}
.financial_handle_report_button_l {
  font-size: 18px;
  padding: 8px 30px;
  border: none;
  font-family: "Segoe UI" !important;
  font-weight: 700 !important;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.financial_handle_report_button_r {
  font-size: 18px;
  padding: 8px 30px;
  border: none;
  font-family: "Segoe UI" !important;
  font-weight: 700 !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* .parent_btn > .btn:hover {
  color: inherit !important;
} */

.aliceBlue {
  background: #ebf9ff;
  color: #636182;
}

.toggleBlue {
  background-color: #19173d;
  color: white;
}
.yearSelectorDocument div {
  margin-left: 5px;
  margin-top: 5px;
}
.report_instruction_div {
  background-color: rgba(237, 237, 237, 1);
  border-radius: 8px;
}
.report_excel_data_filter {
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0;
}
.report_instruction_block {
  background-color: white;
  padding: 7px;
  margin-bottom: 10px;
}
.report_instruction_block_heading {
  font-weight: 600;
  font-size: 14px;
}
.report_instruction_block_subheading {
  font-size: 10px;
  font-weight: 400;
}
.block_custom_width {
  width: 48.9%;
}
.select_custom_class {
  border-color: #ccc;
  /* color: #ccc; */
  width: 47%;
  padding: 8px 10px;
}
.select_custom_class_width_100 {
  border-color: #ccc;
  /* color: #ccc; */
  width: 100%;
  padding: 8px 10px;
}
.select_custom_class .select_custom_class_width_100:focus-visible {
  outline: none !important;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 1rem;
  padding-right: 2rem;
}
.custom-select:focus {
  border: 1px solid #ccc !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.selected_report_section_handing {
  position: relative;
  border: 0.5px solid rgba(194, 113, 0, 0.5);
  background-color: rgba(255, 250, 242, 1);
  width: 40%;
  cursor: pointer;
  border-radius: 5px;
}

.input_handle_colorinput[type="checkbox"] {
  accent-color: #000030;
}

.input_custom_sizes {
  width: 20px;
  height: 20px;
  color: #000030;
}
/* .lock_handling {
  position: absolute;
  width: 6%;
  top: -6px;
  left: -3px;
} */
.lock_container {
  position: absolute;
  width: 18px; /* Adjust size as needed */
  height: 18px; /* Adjust size as needed */
  background-color: rgba(226, 226, 226, 1); /* Background color you want */
  border-radius: 50%; /* Makes the container circular */
  display: flex;
  align-items: center;
  justify-content: center;
  top: -6px;
  left: -6px;
  z-index: 999;
}

.lock_handling {
  width: 12px; /* Adjust to fit nicely inside the container */
  height: 12px;
  object-fit: contain;
}
.periodSelectorDocument {
  margin-top: 5px !important;
}

.document_type_card {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.document_type_card_title {
  padding: 9px !important;
  bottom: 0;
  font-size: 12px !important;
  line-height: 12px !important;
}

.documentModalBody {
  font-size: 12px;
  color: #555;
}

.documentTypeCa4rdImage {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.nolink,
.nolink:hover,
.nolink:visited,
.nolink:focus {
  text-decoration: none !important;
  color: inherit !important;
}

.documentTypeCa4rdImage img {
  height: inherit;
  max-height: inherit;
  max-width: inherit;
}

.document_type_card_title {
  display: flex;
  font-size: 14px;
  text-align: center;
  padding: 12px;
  font-weight: 500;
  /* font-family: serif; */
  font-family: "Segoe UI";
  position: absolute;
  bottom: 0;
}

span,
button,
label {
  font-family: "Segoe UI" !important;
  font-weight: 500 !important;
}

.checkbox {
  margin-right: 1em;
  cursor: pointer;
}

.noDocumentAvailableCard {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #fff7;
  top: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  vertical-align: middle;
}

.noDocumentAvailableCard span {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  font-weight: 500;
  color: #444;
  font-size: 14px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.noDocumentAvailableCard_new {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #fff7;
  top: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  vertical-align: middle;
}
.noDocumentAvailableCard_new span {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  font-weight: 500;
  color: black;
  font-size: 19px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.newsItemDetailBox,
.newsItemImage {
  height: 130px;
  width: 90% !important;
  border: 2px solid rgb(214, 211, 211);
  margin-top: 20px !important;
}

.navbar-nav .nav-link {
  transform: translateY(-5px);
}

.modalpopusContainer {
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.modalpopusContainer span {
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
}

.modalpopusContainer div {
  background-color: #0001;
  padding: 30px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: contain;
  justify-content: center;
  display: flex;
}

.modalpopusContainer img {
  height: 80px;
  object-fit: contain;
}

.css-1haevf0-MuiButtonBase-root-MuiChip-root .MuiChip-avatar {
  margin-top: 0px !important;
}

.css-1hy9t21 {
  object-fit: fill !important;
}

.newsItemIcons {
  position: absolute;
  width: 270px;
  height: 200px;
}

.newsItemIconsdIV {
  position: absolute;
  top: 15px;
  right: 5px;
  display: flex;
}

.newsItemIconsLink {
  padding: 5px;
  background-color: white;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsItemIconsdIV img {
  height: 22px;
  width: 22px;
}

.search_box_company_page {
  /* box-shadow: 0px 0px 2px 0px black; */
  border: 1px solid #cecccc;
  width: inherit;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.search_box_company_page .search_icon_r_box {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search_box_company_page .search_icon_r {
  height: 80%;
  max-height: inherit;
}

.search_box_company_page input {
  border: none;
}

.search_box_company_page input:focus {
  outline: none;
  color: #bfbfbf;
  font-weight: 500;
}

.search_box_company_page.newsoonforum {
  width: 90%;
}

.search_input_ForumNewsPage {
  border: none;
  background-color: transparent;
  width: 100%;
}

.search_input_ForumNewsPage:focus {
  outline: none;
}

.search_input_box_ForumNewsPage {
  padding: 3px;
  border: 1px solid #aaa;
  margin: -3px;
  border-radius: 6px;
  background: #fff;
  height: 40px;
  padding-top: 7px;
}

.search_input_ForumPage {
  border: none;
  background-color: transparent;
  width: 100%;
}

.search_input_ForumPage:focus {
  outline: none;
}

.search_input_box_ForumPage {
  padding: 3px;
  padding-top: 7px;
  border: 1px solid #cecccc;
  border-radius: 6px;
  background: #fff;
  height: 40px;
}

.nav-link {
  cursor: pointer !important;
}

.marginbotton {
  margin-bottom: 10px;
}

.search_input_NewsPage {
  border: none;
  background-color: transparent;
  width: 95%;
}

.search_input_NewsPage:focus {
  outline: none;
}

.search_input_box_NewsPage {
  padding: 3px;
  border: 1px solid #aaa;
  border-radius: 6px;
  height: 40px;
  padding-top: 7px;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.forumpostitem_avatar {
  width: 100px;
  height: 100px;
  margin-top: 8px;
  margin-left: 30%;
}

.forumadditem_avatar {
  width: 100px;
  height: 100px;
  margin-top: 0;
  margin-left: 30%;
}

.forumadditem_username {
  margin-left: 20px !important;
  line-height: 40px !important;
  height: 35px;
  font-size: 14px;
}

.homepage_icon_title_div h3 {
  font-size: 20px;
}

.homepagebothicondiv {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 40px;
}

.companylistingtxt {
  font-size: 12px;
  margin-left: 3px;
}

.icon_container_row span {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.icon_container_row {
  padding-top: 50px;
  padding-bottom: 50px;
}

.homepage_icon_title_div {
  text-align: center;
  width: -webkit-fill-available;
}

.homepage_center_main_div {
  border-radius: 20px;
}

.homepage_icon_span {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: #beb8b8;
  padding-left: 20px;
  padding-right: 20px;
  word-break: break-word;
  display: block;
}

.homepage_icon_container {
  padding: 50px;
  /* box-shadow: 0px 0px 1px 1px rgb(158 156 156); */
  border-radius: 50%;
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remaining_count_detail_page {
  position: absolute;
  bottom: 18px;
  right: 32px;
}
.remaining_count_detail_page_second {
  position: absolute;
  bottom: 86px;
  right: 32px;
}

.homepage_icon_col {
  display: flex;
  justify-content: center;
}

.homepage_icon_container img {
  height: 104px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: auto;
}

.homepage_icons {
  height: 150px;
  width: 150px;
  padding: 10px;
}

.newsForumItemDetailBox,
.newsForumItemImage {
  height: 130px;
  width: 90% !important;
  border: 2px solid rgb(214, 211, 211);
  margin-top: 20px !important;
}

.newsForumItemIcons {
  position: absolute;
  width: 100%;
  right: 2px;
  z-index: 2;
  margin-top: 5px;
}

.newsForumItemIconsLink {
  object-fit: contain;
}

.newsForumItemIconsdIV {
  width: fit-content;
  float: right;
  margin-right: 18px;
}

.newsForumItemIconsdIV img {
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  /* box-shadow: 0px 0px 6px 0px grey; */
}

.bottompadding {
  padding-bottom: 18px;
}

.homepage_container {
  height: 100%;
  padding: 10px;
  min-height: max-content;
}

.css-1pqm26d-MuiAvatar-img {
  object-fit: fill !important;
}

.footer_fluid {
  background-image: linear-gradient(to right, #78c7f2 60%, #b8e5fe, #78c7f2);
  padding-top: 20px;
  padding-bottom: 20px;
  /* height: 200px; */
}

.mobile_footer_img {
  width: 80%;
}

.footer_label {
  font-size: 20px;
}

.footer_label_subscribe {
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.footer_link_list {
  list-style: none;
}

.footer_link_list li {
  margin-top: 14px;
  font-size: 12px;
}

.subscribe_input_footer {
  background-color: #eef5ff;
  border: 1px solid grey;
  height: 30px;
  width: 45%;
  margin-top: 20px;
  font-size: 12px;
  padding: 20px;
  padding-left: 10px;
  padding-right: 10px;
  color: #46484d;
  font-weight: 500;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 51px;
}

.subscribe_input_footer:focus {
  outline: none;
}

.subscribe_input_footer::placeholder {
  text-align: center;
  color: #46484d;
  font-weight: 500;
}

.subscribe_quate {
  font-size: 12px;
  text-align: center;
  display: block;
  /* margin-left: 50px; */
}

.subscribe_btn {
  width: 30%;
  background-color: #040444;
  height: 42px;
  font-size: 13px;
  color: white;
  font-weight: 400;
  margin-top: 1px;
  border: none;
  border-radius: 0px;
  background-color: #001b3d;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.subscribe_btn.subscribed {
  background-color: #6bca65;
}

.authPage_section {
  background-image: linear-gradient(#040911, #052b77);
  height: 100%;
  min-height: 100vh;
  /* width: 100vw; */
  margin: 0;
  padding: 0;
}

.react-tel-input input,
.react-tel-input input:focus,
.selected-flag,
.flag-dropdown {
  background: transparent !important;
  border: none !important;
  box-shadow: none;
}

.otp_page_dis_message {
  color: #706f6f;
  font-size: 17px;
  text-align: center;
  font-weight: 500;
}

.loginbtn_div.otpscreen_btn {
  margin-top: 40px;
}

.otpscreen_highlight_txt {
  color: var(--primary);
}

div[data-testid="otp-input-root"] {
  justify-content: center;
}

div[data-testid="otp-input-root"] input,
div[data-testid="otp-input-root"] input:focus {
  border: none;
  outline: none;
  border-bottom: 3px solid var(--primary);
  width: 100px;
}

.dont_recieve_otp_div {
  text-align: center;
}

.maybe_highlit {
  color: red;
}

.myprofile_section_card {
  background-color: var(--background-material) !important;
}

.myprofile_page_div {
  width: 100%;
  height: 70px;
  padding: 8px;
  padding-top: 15px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px rgb(148, 146, 146);
}

.myprofile_view_all_div {
  display: flex;
  align-items: center;
}

.myprofile_view_all_btn {
  background: #071c47;
  border: none;
  font-size: 11px;
  font-weight: 700;
  color: white;
  border-radius: 8px;
  padding: 4px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: 15px;
}

.myProfile_name_div {
  display: flex;
  padding-right: 0;
  flex-direction: column;
  justify-content: space-evenly;
}

.myProfile_myname {
  font-size: 13px;
  display: block;
  font-weight: 700;
}

.profilecrown {
  height: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.myProfile_myemail {
  font-size: 11px;
  display: block;
  color: #8a8787;
}

.myprofile_menu {
  height: 45px;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: white;
}

.myprofile_menu_main_title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
}

.myprofile_menu_img_div {
  display: flex;
  justify-content: center;
}

.myprofile_menu_img {
  height: 25px;
}

.myprofile_menu_title_div {
  padding-left: 0;
}

.myprofile_menu_title_div a {
  text-decoration: none;
}

.myprofile_menu_title {
  font-size: 12px;
  font-weight: 500;
  color: #222;
}

.myprofile_menu_subscription_div {
  display: flex;
  flex-direction: row;
  height: 27px;
  line-height: 14px;
}

.expireontitle {
  font-size: 9px;
  color: red;
}

.myprofile_upgrade_subscript {
  display: flex;
  justify-content: end;
  color: #555;
  font-size: 13px;
  align-items: center;
}

.myprofile_upgrade_subscript button {
  background-color: #b90a30;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 10px;
  padding: 5px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 3px;
}

.company_details_company_div div {
  object-fit: contain;
}

.company_details_company_div img {
  max-width: 100%;
  max-height: 51px;
  float: right;
}

.no_news_txt {
  font-size: 12px;
  color: #555;
}

.subscription_modal_title {
  text-align: center;
  width: 100%;
  color: #0c0c63;
  font-weight: 600 !important;
}

.subscription_desc_row {
  color: #707070;
}

.modal-header {
  border-bottom: none;
}

.subscription_subtitle {
  font-size: 24px;
  color: #0c0c63;
  font-weight: 600;
}

.subscription_pricetitle {
  font-size: 18px;
  color: #0c0c63;
  font-weight: 600;
}

.subsc_price_box {
  width: 500px;
}

.subscription_modal_box {
  background: #f3f2f7;
  padding: 29px;
  border-radius: 14px;
  height: 500px;
}

.subscription_desc_box {
  height: 300px;
}

.subscription_modal_main_boxs {
  padding-left: 30px;
  padding-right: 30px;
}

.subscription_modal_body {
  padding-top: 0px;
  padding-bottom: 30px;
}

.subscription_modal {
  border-radius: 30px;
}

.subscribe_btn:hover {
  color: white;
}

.global_search_box {
  height: 60px;
  display: flex;
  align-content: center;
}

.global_search_input {
  width: 100%;
  height: 38px;
  border: none;
  color: rgb(131, 127, 127);
}

.searchinputbox_homepage {
  border: 0px solid #787272;
  box-shadow: 0px 0px 3px 0px #9b9494;
  border-radius: 10px;
  background-color: white;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  height: 41px;
  margin-bottom: 7px;
}

.global_search_input::placeholder {
  color: rgb(131, 127, 127);
}

.searchinputbox_homepage img {
  width: 18px;
  margin-right: 20px;
  margin-left: 7px;
}

.paypal-button-shape-rect.paypal-button-number-multiple.paypal-button-env-sandbox.paypal-button-color-black {
  display: none;
}

.global_search_input:focus {
  outline: none;
}

.companysearch_input_recentsearch {
  margin-bottom: 10px;
}

.company_list_card {
  padding-bottom: 15px;
}

.news_cards_div {
  margin-bottom: 10px;
}

.payment-div {
  height: 40px;
  overflow: hidden;
}

.back_btn_txt {
  position: absolute;
  color: #72c4f1;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: baseline !important;
}

.back_btn_txt2 {
  color: #72c4f1;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.back_btn_txt2 img {
  margin-right: 2px;
}

.back_btn_detailpage {
  border: none;
  height: 30px;
  margin-top: 7px;
  color: #72c4f1;
}

.backbutton_btn {
  position: absolute;
  top: 21px;
  left: 21px;
}

.popup_company_box {
  display: flex;
  justify-content: center;
}

.popup_company_box img {
  height: 30px;
}

.popup_company_box span {
  font-size: 16px;
  margin-left: 10px;
  height: 30px;
}

.company_pop_r.modal-dialog {
  max-width: 768px !important;
  /* left: 41% !important;
    transform: translateX(-50%) !important; */
}

.modal-content.company_pop_r {
  height: 480px;
}

.company_pop_r.box_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.company_pop_r.box_inner span {
  font-size: 22px;
}

.round_company_circle {
  height: 250px;
  width: 250px;
  border: 1px solid #e5e9ea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
}

.round_company_circle img {
  max-width: 50%;
  max-height: 50%;
}

.company_pop_r.delisted_txt,
.delisted_txtc {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
  font-size: 12px;
  color: #444;
}

.delisted_txtc {
  align-items: flex-start;
  flex-direction: row;
}

.delisted_txtc .redx {
  color: red;
}

.com_details_lock_icon {
  position: absolute;
  top: 10px;
  left: 10px;
  border: none;
  z-index: 0;
  background-color: transparent;
}

.com_details_lock_icon img {
  height: 20px;
  width: 20px;
}

.com_details_exclamatoy_icon {
  float: inline-end;
  margin-right: 5px;
  margin-top: 3px;
  /* display: flex;
  flex-direction: row-reverse; */
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 0;
  border: none;
  background-color: transparent;
}
.com_details_exclamatoy_icon img {
  height: 10px;
  width: 10px;
}
.com_details_exclamatoy_icon_finan {
  position: relative;
}
.com_details_exclamatoy_icon_finan img {
  height: 10px;
  width: 10px;
  position: absolute;
  right: 3px;
  top: 2px;
}

.select_peroid_label {
  font-size: 15px;
  text-align: center;
}

.yearSelectorDocument {
  display: flex;
}

.company_details_company_div {
  color: #474545;
  font-size: 18px;
  font-weight: 500;
  width: inherit;
}

.company__country_div_details {
  background-color: white;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  padding: 10px 0px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}
.company__country_div_details_finan {
  background-color: #ebf9ff;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px 0px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.css-9mgopn-MuiDivider-root {
  border-bottom-width: initial !important;
}

.footer_container a {
  text-decoration: none;
  color: black;
}

.navbar_toogle_box {
  display: none;
}

.search_input_companyPage {
  width: 80%;
  background-color: transparent;
}

.subscription_modal_dialog {
  max-width: 1200px;
}

.chatlist_container {
  border-radius: 13px;
  height: 80px;

  margin-top: 0px;
  margin-bottom: 0px;
}

.chatlistdivouter {
  margin-top: 15px;
  margin-bottom: 15px;
}

.chat_list_message {
  color: #2e2c2c;
  font-size: 0.8rem;
}

.chat_list_username {
  font-weight: 900px;
  font-size: 15px;
  margin-top: 6px;
  color: #070707;
}

.commentinput {
  border: none;
  width: 120%;
}

.commentinput:focus {
  outline: none;
}

.comment_input_box {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
}

.morerepliesdiv {
  text-align: right;
  margin-top: -24px;
}

.morerepliestxt {
  background-color: transparent;
  border: none;

  font-size: 12px;
}

.addcommentinput {
  border: 1px solid black;
  border-radius: 13px;
  margin-left: 20px;
}

.commentInput_icon_box {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: right;
}

.commentInput_icon {
  height: 50px;
  margin-top: 5px;
}

.commentInput_post_btn {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.addcommentcontainer {
  margin-bottom: 10px;
  margin-top: 10px;
}

.commentreplaybox span {
  width: fit-content;
}

.commentreplaybox,
.comment_timing_txt_box {
  text-align: center;
}

.commentreplaybtnn {
  background-color: transparent;
  border: none;
  color: #070707;
  font-weight: 900px;
  font-size: 12px;
}

.commentreplaybtn {
  text-align: right;
}

.comment_timing_txt {
  color: #a2a2a2;
  font-size: 10px;
}

.comment_menu_box {
  display: flex;
  flex-flow: column;
  width: 113px;
  align-items: flex-start;
  margin-left: 11px;
}

.pollprogressbar {
  height: 35px;
  width: 90%;
  border: 1px solid #707070;
  margin: 5px;
  border-radius: 10px;
}

.pollprogress span b {
  float: left;
}

.pollprogress span {
  float: right;
  padding-left: 10px;
  padding-right: 10px;
}

.poloptionpercentage1 {
  position: absolute;
  display: inline;
  float: left;
  left: 10px;
  top: 5px;
}

.poloptionpercentage2 {
  position: absolute;
  display: inline;
  float: right;
  width: 100%;
  top: 5px;
}

.poloptionpercentage2 b {
  float: right;
  margin-right: 10px;
}

.pollprogress {
  background-color: #e6fca8;
  height: 100%;
  width: var(--mywidth);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addforumbtn {
  position: fixed !important;
  top: 73px;
  z-index: 5;
  left: 50vw;
  height: 50px;
  width: 50px !important;
  display: flex !important;
  justify-content: center !important;
  border-radius: 40px !important;
  align-items: center !important;
  padding-top: 2px !important;
}

.addforumbtn::after {
  content: "ADD FORUM";
  color: #72c4f1;
  position: absolute;
  bottom: -26px;
  display: inline;
  width: 100px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}

.addforumlabelasheading {
  margin-top: 22px;
}

.forumactionbtns {
  width: 50px;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
}

.addforuminput {
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px;
  color: #4e4d4d;
  border: 1px solid #cec9c9;
  font-weight: 500;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}

.addforuminput:focus {
  outline: none;
}

.addforuminput::placeholder {
  color: #9c9c9c;
}

.uploadBtnForumAdd,
.uploadBtnForumAddActionArea {
  background-color: #72c4f1;
  border-radius: 30px;
  color: #fff;
  border: none;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 0px 0px 1px 0px #888;
}

.uploadBtnForumAddActionArea {
  width: 150px !important;
  border-radius: 10px !important;
}

.addforumradiogroupbox {
  padding-top: 15px;
  padding-bottom: 15px;
}

.uploadBtnForumAddBox {
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer_cp_row {
  background-color: #001b3d;
  color: white;
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}

.footer_copyright_label {
  font-size: 12px;
}

.footer_mobile_image {
  width: 50%;
  /* height: 230px; */
  padding: 1px;
  border-radius: 5px;
}

.searchboxcompanypage {
  height: 50px;
}

.search_input_companyPage {
  color: #000;
}

.search_input_companyPage:focus,
.search_input_companyPage:hover {
  color: #000;
}

.newscarddescbox {
  height: 83px;
  padding: 10px;
}

.subscription_alert_box {
  min-height: 35px;
}

.css-o69gx8-MuiCardMedia-root {
  object-fit: contain !important;
  border-radius: 5px;
}

.forum_post_image {
  object-fit: cover !important;
  border-radius: 5px;
}

.forum_post_content::first-letter {
  text-transform: capitalize !important;
}

.news_media_image {
  object-fit: cover !important;
  width: 100px;
}

.forumpostbtn_row {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.forumpostbtn {
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.forumUploadedDeleteBtn {
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  position: absolute;
  right: 27px;
  top: 23px;
  transform: translate(4px, -14px);
  filter: drop-shadow(0px 6px 10px grey);
  display: flex;
}

.forumUploadedDeleteBtnIcon {
  height: inherit;
  width: inherit;
}

.forumpost_link {
  color: rgb(80, 185, 242) !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.addforumradiogroup {
  display: flex;
  align-items: baseline;
}

.addforumradiogroup input {
  margin-right: 5px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  height: 13px;
  width: 15px;
  border-radius: 50%;
  padding: 2px;
  border-collapse: collapse;
}

.addforumradiogroup input::after {
  content: "";
  border-radius: 50%;
  border: 1px solid #707070;
  height: 20px;
  width: 20px;
  scale: 1.5;
  transform: translate(-3.5px, -3.5px);
  position: absolute;
}

.addforumradiogroup input[type="radio"]:checked {
  appearance: none;
  background-color: #72c4f1;
}

.addRowInPoll {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 3px 8px 0px gray;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.addRowInPoll img {
  height: inherit;
}

.poll_timing_select {
  border: 1px solid #707070;
  padding: 10px;
  background: transparent;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  appearance: none;
}

.pollOptionInput {
  height: 50px;
  border: 1px solid black;
  border-radius: 20px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.pollOptionDeleteBtn {
  position: absolute;
  height: 30px;
  width: 30px;
  margin-top: 20px;
  border: none;
  background: none;
  right: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgb(177, 175, 175);
}

.err_text {
  font-size: 12px;
  color: red;
}

.documentdetailsection {
  min-height: 0vh;
}

.backtoprevioustextdiv {
  height: 30px;
}

.backtoprevioustext {
  background-color: transparent;
  border: none;
  font-size: 13px;

  padding-bottom: 0.5px;
  color: #070707;
}

.modal-90w {
  min-width: 90% !important;
}

.add_image_icon {
  position: absolute;
  bottom: 6px;
  margin-left: 40px;
  height: 23px;
  width: 23px;
}

.edit_delete_forum_btn_bx {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.edit_delete_forum_btn {
  height: 20px;
  width: 20px;
}

.company_details_visits {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  line-height: 12px;
  color: #555;
}
.company_details_visits_second {
  font-size: 10px;
  line-height: 12px;
  color: #555;
}
.downloadstoreiconrows,
.subscription_form,
.userful_links_row {
  margin-top: 40px;
}

.store_icons img {
  width: 120px;
}

.forum_action_btn_devider {
  border-top: 1px solid #e0dddd;
  margin-top: 6px;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

/* Notifications */

.notification_menu .css-6hp17o-MuiList-root-MuiMenu-list {
  width: 350px !important;
  padding: 0 !important;
}

.see_more_news_notification_div {
  background-color: #4441;
}

#account-menux .css-r8u8y9 {
  width: 350px !important;
  padding: 0px !important;
}

.see_more_news_notification {
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.notification_bell_image {
  width: 25px;
  height: 25px;
  margin-top: 3px;
}

.notification_bell_imagepng {
  height: inherit;
}

.notification_bell_div {
  width: 40px;
  height: 25px;
  margin-right: 14px;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.notification_bell_div .css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}

.notification_bell_counter {
  height: 18px;
  width: fit-content;
  min-width: 15px;
  background: red;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 5;
  padding: 5px;
}

.notification_time {
  font-size: 10px;
  color: #555;
  margin-right: 5px;
}

.ndfHFb-c4YZDc-i5oIFb.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-Wrql6b {
  display: none !important;
}

.notification_bell_counter_for_mobile {
  align-items: center;
  background: red;
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 18px;
  justify-content: center;
  /* left: 10px; */
  min-width: 15px;
  padding: 5px;
  position: absolute;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 5;
  margin-left: 10px;
  margin-top: 3px;
}

.doc_viewr {
  transform: translateY(-5%);
  height: 150vh;
  width: inherit;
}

.notification_bell_image::after {
  content: "1";
  background-color: red;
  height: 10px;
  width: 10px;
  position: absolute;
}

.notification_div {
  border: 1px solid #5553;
  padding: 5px;
  cursor: pointer;
}

.notificationList_div {
  padding: 3px;
  cursor: pointer;
  border-bottom: 1px solid #5553;
}

.notification_image {
  width: inherit;
  max-height: 50px;
  object-fit: contain !important;
}

.notificationlist_image {
  width: inherit;
  max-height: 40px;
  object-fit: contain !important;
}

.notifications_popup_heding {
  background-color: #5552;
  margin-top: 0;
  margin-bottom: 0;
  height: 25px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-size: 13px;
}

.notification_text,
.notificationlist_text {
  color: #555;
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 11px;
  margin-top: 5px;
  height: 22px;
}

.notificationlist_text {
  -webkit-line-clamp: 2;
  height: 23px;
  color: #555;
}

.notification_title_text {
  font-size: 12px;
  display: block;
  line-height: 10px;
}

.notification_title_time {
  display: flex;
  justify-content: space-between;
  line-height: 10px;
}

.notification_flag_image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-top: 5px;
  padding: 4px;
  object-fit: fill !important;
}

.notificationlist_flag_image {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin: 0;
  object-fit: fill !important;
  margin-left: 5px;
}

.terms_ply_div {
  margin-bottom: 20px;
}

.userlinks_div {
  text-align: end;
}

.header_profile_cirle {
  height: 27px !important;
  width: 27px !important;
  margin-top: 2px;
  margin-right: 9px;
}

.notification_menu {
  transform: translate(147px, 12px);
}

.backbutton_btn {
  border: none;
  margin-left: 18px;
}

.seachboxHome_outer {
  width: 100%;
}

.seachboxHome_input {
  width: 100%;
  font-size: 13px;
  color: #555;
}

.holder {
  position: relative;
}

.dropdown {
  position: absolute;
  border: 1px solid rgb(196, 189, 189);
  display: none;
  z-index: 1;
  background: white;
  outline: none;
  padding: 5px;
}

input:focus + .dropdown,
.dropdown:hover,
.seachboxHome_item:hover ~ .dropdown,
.seachboxHome_item:hover + .dropdown,
.seachboxHome_item:focus + .dropdown {
  display: block;
}

.seachboxHome_outer .holder input {
  padding-left: 40px;
  height: 35px;
  outline: none;
}

.seachboxHome_outer .dropdown {
  width: 100%;
  border-radius: 1px solid black;
}

.seachboxHome_icon {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  position: absolute;
}

.seachboxHome_item {
  margin: 5px 0px;
  display: flex;
  height: 25px;
  cursor: pointer;
}

.seachboxHome_flag {
  width: 40px;
  height: fit-content;
  align-items: center;
}

.seachboxHome_name {
  font-size: 12px;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
  line-height: 12px;
  height: 12px;
}

/* MidSection CSS start */
.mid-section-wrapper {
  margin-top: 10px;
  padding: 0px 15px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.mid-section-back-btn-div .mid-section-back-btn {
  text-decoration: none;
  color: #72c4f1;
  font-size: 14px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
}

.mid-section-back-btn-div .mid-section-back-btn span {
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
}

.mid-section-back-btn-div .mid-section-back-btn img {
  width: 15px;
  height: 15px;
  padding-bottom: 1px;
  transform: rotateZ(90deg);
  margin-right: 2px;
}

.mid-section-heading h2 {
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0px !important;
  text-align: start !important;
  /* text-transform: capitalize !important; */
}

.mid-section-date-name-div {
  padding-bottom: 14px;
  padding-top: 6px;
  border-bottom: 1px solid #eee;
}

.mid-section-date-name-div .mid-section-date {
  color: #000000;
  font-size: 12px;
}

.mid-section-date-name-div .mid-section-user-name {
  color: #cccccc;
  font-size: 11px;
  margin-left: 12px;
}

.mid-section-img-div {
  margin-top: 15px;
  border-bottom: 1px solid #eee;
  width: 100%;
  /* height: 450px; */
  overflow: hidden;
}

.mid-section-img-div .mid-section-img {
  width: 100%;
  /* max-height: 350px; */
  object-fit: contain;
}

.mid-section-img-detail p {
  color: #000000;
  font-size: 11px;
  margin-top: 5px;
  margin-bottom: 8px;
}

.mid-section-description {
  padding-top: 18px;
}

.mid-section-description p {
  font-size: 16px;
  color: #000000;
}

.mid-section-description p a {
  color: #000000;
  text-decoration: none;
}

.mid-section-description p a:hover {
  color: #72caf1;
}

/* .mid-section-social-div .twitter-follow-button{
    background-color: lightblue;
    padding: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
} */

/* .mid-section-social-div .twitter-share-button {
    margin-right: 10px;
}

.mid-section-social-div .mid-section-follow-us {
    color: #000000;
    font-size: 18px;
    margin-right: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

.mid-section-social-div .IN-widget {
    margin-right: 5px !important;
}

.mid-section-social-div .fb-share-button {
    display: flex !important;
    align-items: center !important;
} */

.mid-section-social-div {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 15px 0px;
  /* display: flex; */
  margin-top: 20px;
  margin-bottom: 50px;
}

.mid-section-right-wrapper {
  padding-top: 80px;
}

.mid-section-right-wrapper .mid-section-right-most-read {
  color: #000000;
  padding-bottom: 4px;
  font-weight: 600;
  border-bottom: 2px solid #000000;
  font-size: 16px !important;
}

.mid-section-right-article-links {
  border-bottom: 1px solid #eee;
}

.mid-section-right-article-links-img {
  display: flex;
  align-items: center;
  margin-right: 10px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.mid-section-right-article-links-img img {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  object-fit: cover;
}

.mid-section-right-article-links a {
  color: #000000;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 16px;
}

.mid-section-right-article-links a:hover {
  color: #72caf1;
}

._3vtw img {
  width: 50px !important;
  height: 50px !important;
}

.mid-section-fb-btn-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  padding: 3px 5px;
  border-radius: 3px;
  width: fit-content;
}

.mid-section-links-name-date-div {
  justify-content: space-between;
}

.mid-section-right-article-links-wrapper {
  line-height: 18px;
  width: 100%;
  overflow-x: hidden;
}

.mid-section-right-article-links-title {
  /* text-transform:capitalize !important; */
}

.mid-section-links-name-date-div .mid-section-links-date {
  color: #000000;
  font-size: 12px;
}

.mid-section-links-name-date-div .mid-section-links-name {
  color: #cccccc;
  font-size: 11px;
}

.mid-section-description-attachment-wrapper {
  display: block;
  align-items: center;
  font-size: 16px;
  font-weight: normal !important;
}

.mid-section-description-attachment-wrapper img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.mid-section-description-attachment-wrapper a {
  text-decoration: none;
  color: #000000;
  display: flex;
  align-items: center;
}

.mid-section-description-attachment-wrapper a:hover {
  color: #72c4f1;
}

.mid-section-description img {
  width: 100% !important;
}

.financial-toggle-button-div .financial-toggle-button1 {
  background-color: transparent;
  border: 1px solid #bdbdbd;
  padding: 5px 10px;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.financial-toggle-button-div .financial-toggle-button2 {
  background-color: transparent;
  border: 1px solid #bdbdbd;
  padding: 5px 10px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-left: 0px;
}

.iDetailbtn {
  border: 1px solid #bdbdbd;
  font-size: 15px;
}

.iDetailbtnColor {
  background-color: #72c4f1;
}

.iDetailbtn:nth-child(1) {
  border-end-end-radius: 0px;
  border-start-end-radius: 0px;
  border-right: 0px;
}

.iDetailbtn:nth-child(2) {
  border-end-start-radius: 0px;
  border-start-start-radius: 0px;
}

.iDetailbtn:focus {
  box-shadow: none !important;
}

.right-div-error {
  display: flex;
  justify-content: center;
  /* color: red; */
  font-size: 20px;
}

.modal_contact_details {
  width: 100%;
  justify-content: center;
  display: flex;
}
.custom_position {
  cursor: pointer;
}

.twitter-share-icon-div {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
}

.twitter-share-icon-div img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.linkedin-share-icon-div img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.facebook-share-icon-div img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.mid-section-follow-us {
  margin-right: 12px;
  font-size: 16px;
}

.custom-feed-loader-div {
  /* height: 100vh; */
  min-height: 200px;
  display: flex;
  align-items: center;
}

.custom-feed-loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid black;
  border-top-color: transparent;
  animation: rot1 1.2s linear infinite;
}

.filesDownload {
  display: inline-block;
  width: 100%;
}

@keyframes rot1 {
  to {
    transform: rotate(360deg);
  }
}

.mid-section-description table {
  width: 100% !important;
  overflow-x: auto;
}

.mid-section-description table tr {
  border: 1px solid #ebebeb;
}

.mid-section-description table tr td {
  font-size: 14px !important;
  text-align: left !important;
  padding: 3px !important;
  border: 1px solid #ebebeb;
}

.mid-section-img-title {
  font-size: 16px;
  text-transform: capitalize;
}

.mid-section-img-description {
  font-size: 15px;
  /* text-transform: capitalize; */
  margin-bottom: 12px;
}

.ksa-markets {
  background-color: #005430 !important;
  color: white !important;
}

.uae-markets {
  background: linear-gradient(
    180deg,
    rgba(0, 151, 57, 1) 0%,
    rgba(0, 0, 0, 1) 65%
  ) !important;
  color: white !important;
}

.kuwait-markets {
  background: linear-gradient(
    180deg,
    rgba(0, 122, 61, 1) 0%,
    rgba(206, 17, 38, 1) 100%
  ) !important;
  color: white !important;
}

.bahrain-markets {
  background-color: #ce1126 !important;
  color: white !important;
}

.qatar-markets {
  background-color: #8a1538 !important;
  color: white !important;
}

.oman-markets {
  background: linear-gradient(
    180deg,
    rgba(200, 16, 46, 1) 0%,
    rgba(0, 154, 68, 1) 100%
  ) !important;
  color: white !important;
}

.download_btn {
  position: absolute;
  right: 35px;
  bottom: 35px;
}

.spinner-text {
  padding-left: 10px;
}

.excel-btn {
  /* min-width: 216px !important; */
  /* min-height: 44px !important; */
}

.download_btn_home {
  margin: 2em 26em;
}

.input_box {
  height: 3em;
}

.newsItemBox {
  cursor: pointer;
}

.div-disabled {
  pointer-events: none;
  background-color: #ebebe4;
}

/* .excel-btn {
    background-color: #72C4F1 !important;
} */

.toast-err {
  position: absolute;
  left: 35px;
  bottom: 35px;
  border: 1px solid #ff7474;
  border-radius: 10px;
}

.toast-heading {
  padding: 40px 15px;
}

.toast-alert-div {
  position: absolute;
  width: 16%;
  left: 40%;
  top: -22%;
  border-radius: 30px;
  border-top: 1px solid #ff7474;
}

.close {
  width: 14%;
}

.close-img {
  width: 100%;
  object-fit: contain;
}

.modal-document-check {
  display: flex;
  padding-left: 14px;
}

.document-check-line {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
}

.cross-line {
  color: #ff4141;
}

.modal-body-content {
  color: #707070;
  font-size: 16px;
  display: flex;
  padding: 0px 16px;
  text-align: center;
}

.modal-icon {
  width: 4%;
  object-fit: contain;
}

.ashom_plus_icon {
  width: 138px !important;
  height: auto !important;
}

.video-modal {
  padding: 0px !important;
}

.video-modal > .modal-dialog-centered {
  max-width: -webkit-fill-available;
  height: -webkit-fill-available;
  margin: 17px;
}

.video-modal > .modal-dialog-centered > .modal-content {
  height: inherit;
}

.video-tutorial-heading {
  /* background-color: #e7e7e7;
    padding: 10px; */
  border-bottom: 3px solid rgb(187, 193, 197);
  cursor: default !important;
}

.video-tutorial {
  /* background-color: rgb(223 223 223); */
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: rgb(0, 0, 48);
  text-align: start;
}

.video-tutorial-sub-heading {
  font-size: 20px;
}

.video-div-container {
  height: 78vh;
}

.loginTitle {
  color: #6b6965;
  font-size: 14px;
  text-align: center;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
  padding-right: 0px !important;
}

.btn:disabled {
  margin: 0 auto;
  vertical-align: middle;
  padding: auto;
  opacity: 0.5;
}

.spinner {
  opacity: 0.5;
}

#showMe {
  animation: cssAnimation 0s 5s forwards;
  visibility: hidden;
}

.menu-video-div {
  margin: 16px 10px;
  border-radius: 10px;
  background: #ffffff;
}

.video-modal-body {
  background: rgb(114 196 241 / 25%);
}

.video-div-container {
  background: #ffffff;
  padding: 0px;
  margin: 16px;
  max-width: 82.333333%;
  border-radius: 10px;
}

.video-tutorial-number {
  border-radius: 20px;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: bold;
}

.video-download-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.tutorial-watch-btn {
  position: absolute;
  right: 310px;
  bottom: 35px;
}

.plyr {
  min-width: 100% !important;
  min-height: 100%;
  border-radius: 10px;
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}

@media (max-width: 1400px) {
  .video-div-container {
    height: 74vh;
  }
}

@media (max-width: 1199px) {
  .video-modal-body {
    display: block !important;
    padding: 0px !important;
  }

  .menu-video-div {
    display: flex !important;
  }

  .video-div-container {
    height: 64vh;
    max-width: 97%;
  }
}

@media only screen and (max-width: 1050px) {
  /* For mobile phones: */
  [class*="morerepliestxt"] {
    margin-left: 10rem;
  }

  .video-modal > .modal-dialog-centered {
    height: 890px;
  }

  .video-div-container {
    height: 46vh;
  }
}

@media (max-width: 992px) {
  .mid-section-social-div {
    display: block !important;
    justify-content: center !important;
    margin-bottom: 20px;
  }

  .mid-section-right-wrapper {
    padding: 20px;
  }

  .mid-section-follow-us {
    margin-bottom: 10px !important;
    font-size: 16px !important;
  }

  .video-modal > .modal-dialog-centered {
    height: 564px;
  }

  .video-div-container {
    height: 40vh;
  }
}

@media only screen and (max-width: 950px) {
  /* For mobile phones: */
  [class*="morerepliestxt"] {
    margin-left: 10rem;
  }

  .video-modal > .modal-dialog-centered {
    height: 810px;
  }

  .video-div-container {
    height: 40vh;
  }
}

@media only screen and (max-width: 820px) {
  /* For mobile phones: */
  [class*="morerepliestxt"] {
    margin-left: 10rem;
  }

  .video-modal > .modal-dialog-centered {
    height: 730px;
  }

  .video-div-container {
    height: 40vh;
  }
}

@media (min-width: 768px) {
  .company_pop_r {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }

  .terms_ply_div {
    min-height: 300px;
  }
}

@media (max-width: 768px) {
  .video-modal > .modal-dialog-centered {
    height: 576px;
  }

  .video-div-container {
    height: 54vh;
    max-width: 96%;
  }

  .video-download-btn {
    right: 14px;
  }

  .css-iu2ioh-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
    right: 80px !important;
  }

  .notification_menu {
    transform: none;
  }

  .terms_ply_div {
    min-height: 550px;
  }

  .yearSelectorDocument {
    display: flex;
    overflow-x: auto;
  }

  .periodSelectorDocument {
    display: flex;
    overflow-x: auto;
  }

  .company_details_period_section {
    height: auto;
  }

  .labelasheading {
    font-size: 14px;
  }

  .company_details_document_section {
    height: auto;
    padding: 10px;
  }

  .documentTypeCa4rdImage {
    margin-top: 20px;
  }

  .homepage_container {
    height: auto;
  }

  .global_search_input {
    height: fit-content;
  }

  .company_pop_r.box_inner {
    margin-bottom: 16px;
  }

  .modal-content.company_pop_r {
    height: auto;
  }

  .popup_company_box span {
    height: auto;
  }

  .navbar-nav {
    display: flex;
    flex-direction: inherit;
    justify-content: space-between;
  }

  .headerprofile {
    display: none;
  }

  .navbar_toogle_box {
    display: flex;
  }

  .news_section_in_forum {
    display: none;
  }

  .newsItemIcons {
    width: 100%;
  }

  .newsItemIconsdIV {
    position: absolute;
    top: 17px;
    right: 40px;
  }

  .subscription_modal_dialog {
    max-width: fit-content;
  }

  .homepagebothicondiv {
    padding-left: 0px;
    padding-right: 0px;
  }

  .searchboxcompanypage {
    height: 100px;
  }

  .search_box_company_page .search_icon_r_box {
    width: 41px;
  }

  .search_box_company_page {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .navbar-nav {
    /* transform: translateX(-50%); */
    width: 100vw !important;
    /* margin-left: 42%; */
  }

  .search_input_box_ForumPage {
    width: 100%;
    margin: 0;
  }

  .addforumbtn {
    top: unset;
    left: unset;
    bottom: 30px;
    right: 30px;
  }

  .css-o69gx8-MuiCardMedia-root {
    height: 210px !important;
    background-image: attr("src");
    object-fit: contain !important;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list {
    display: flex;
    flex-flow: column;
    width: 170px;
  }

  .newscarddescbox {
    height: 95px;
    padding-top: 5px;
  }

  .downloadstoreiconrows {
    height: unset;
    transform: unset;
  }

  .homepage_center_main_div {
    margin: 16px;
  }

  .homepage_icon_span {
    padding-bottom: 20px;
  }

  .newstimingtext {
    padding-right: 20px;
  }

  .navbar-nav .nav-link {
    transform: translateY(-11px);
  }

  .nav-item {
    margin-left: 5px;
    margin-right: 5px;
  }

  .myprofile_menu_subscription_div {
    padding: 0;
  }

  .notification_bell_div {
    height: 30px;
    margin-right: 11px;
    margin-top: 1px;
    width: 30px;
  }

  .notification_bell_counter {
    position: absolute;
    height: 19px;
    margin-left: 12px;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 15px;
    background: red;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    top: 6px;
    left: initial;
    z-index: 5;
    padding: 5px;
  }

  .subscribe_quate {
    margin-left: 0px;
  }

  .userlinks_div {
    text-align: center;
  }

  .pollprogressbar {
    width: 1000%;
  }

  .labelasheading {
    flex-flow: column;
    align-items: baseline;
  }

  .forumadditem_avatar {
    margin: 0;
  }

  .nopaddingcontainer {
    padding: 0 !important;
    width: inherit;
    overflow: hidden;
  }

  .backbutton_btn {
    margin: 0;
  }

  .company_pop_r.box_inner span {
    font-size: 14px;
  }

  .round_company_circle {
    height: 120px;
    width: 120px;
  }

  .popup_company_box span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .video-div-container {
    height: 41vh;
  }

  .video-modal > .modal-dialog-centered {
    height: 668px;
  }
}

@media (max-width: 600px) {
  .video-modal > .modal-dialog-centered {
    height: 580px;
  }

  .video-div-container {
    height: 40vh;
    max-width: 95%;
  }
}

@media only screen and (max-width: 540px) {
  .video-modal > .modal-dialog-centered {
    height: 556px;
  }

  .video-tutorial {
    font-size: 12px;
  }

  .video-tutorial-sub-heading {
    font-size: 16px;
  }

  .responsive-video-heading {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .video-modal > .modal-dialog-centered {
    height: 500px;
  }

  .video-div-container {
    height: 30vh;
    max-width: 93%;
  }

  .video-tutorial {
    font-size: 10px;
  }

  .video-tutorial-sub-heading {
    font-size: 14px;
  }
}

@media only screen and (max-width: 490px) {
  /* For mobile phones: */
  [class*="morerepliestxt"] {
    margin-left: 10rem;
  }
}

@media only screen and (max-width: 360px) {
  /* For mobile phones: */
  [class*="morerepliestxt"] {
    margin-left: 10rem;
  }

  .video-modal > .modal-dialog-centered {
    height: 492px;
  }

  .video-div-container {
    height: 27vh;
    max-width: 90%;
  }
}
